<template>
  <div>
    <div class="maintitle">交易流水</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-col :span="14">
        <el-date-picker
          v-model="timerange"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          style="width:250px;"
          class="margin-big-left"
        ></el-date-picker>
        <el-button class="margin-left" @click="getlist">查询</el-button>
      </el-col>
      <el-col :span="10">
        <span>
          总交易额：
          <span style="font-weight:bold;" class="text-success">{{totalmoney}}元</span>
        </span>
      </el-col>
    </el-row>

    <el-table :data="list" border>
      <el-table-column align="center" fixed prop="ordersn" label="订单号"></el-table-column>
      <el-table-column align="center" label="金额" prop="total"></el-table-column>
      <el-table-column sortable align="center" prop="sgtime" label="时间"></el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      list: [],
      page: 1, //当前页数
      total: 0, //信息总数（所有页）
      timerange: [this.getdate("start"), this.getdate("end")], //查找时间范围，默认为本月
      totalmoney: -1
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      this.$axios({
        url: this.HOST + "/admin/member/getRecord",
        method: "post",
        data: {
          openid: this.$route.params.id,
          page: this.page,
          pagesize: this.pagesize,
          timerange: this.timerange
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
          if (res.data.total) {
            this.totalmoney = res.data.total;
          }
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    getdate(str) {
      let date = new Date();
      if (str == "start") {
        return (
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + "01 00:00:00"
        );
      } else {
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " 23:59:59"
        );
      }
    }
  },
  created: function() {
    this.getlist();
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>